import React from 'react';
import { motion } from 'framer-motion';

import Layout from '../components/layout';
import SEO from '../components/seo';

import '../styles/global.scss';
import '../styles/emailLinkError.scss';

const EmailDefaultError = () => (
    <Layout>
        <SEO title="Email Link Error" />
        <section className="emailLinkError section">
            <div className="container pageHero">
                <div className="textBlock">
                    <motion.h1
                        initial={{
                            opacity: 0,
                            scale: 0.8,
                            y: 20,
                        }}
                        animate={{
                            scale: 1,
                            opacity: 1,
                            y: 0,
                        }}
                        transition={{
                            duration: 1,
                        }}
                        className="pageHeadline"
                    >
                        Email Link Error
                    </motion.h1>
                </div>
            </div>
        </section>

        <div className="errorBlock">
            <div className="app-bodyHeadline">Follow These Steps to Login</div>
            <div className="helpText">
                <ol>
                    <li>
                        1. Go back to the email and <strong>Long Press</strong> the link in the
                        email.
                    </li>
                    <li>2. In the pop up, select “Open in Earth Hero”</li>
                </ol>
                <p>
                    Sorry for the inconvenience. It’s an uncommon quirk of how your device is setup.
                </p>
                <p>
                    If that doesn’t work, you may need to send a new email link from the app and
                    then follow the steps above.
                </p>
                <p>
                    After doing these steps once you should be fine. If not, please let us know at
                    contact@earthhero.org
                </p>
            </div>
        </div>
    </Layout>
);

export default EmailDefaultError;
